@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DMSans-Medium.ttf);
}

* {
  font-family: "DM Sans";
}

html,
body {
  padding: 0;
  margin: 0;
}

.widget{
  position: fixed;
  bottom: 10%;
  right: 3%;
}

.widget img{
  object-fit: cover;
  width: 50px;
  height: 50px;
}